<template>
  <div class="content-area">
    <hero-bar>
      {{ $t('editCar') }}
      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{ 'is-loading': isLoading }"
        @click="save(id)"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component :title="$t('carDetails')" icon="car-info">
        <form>
          <b-field horizontal :label="$t('plate')">
            <b-input :value="car.plate" disabled expanded />
          </b-field>

          <b-field horizontal :label="$t('brand')">
            <b-input :value="car.brand" disabled expanded />
          </b-field>

          <b-field horizontal :label="$t('model')">
            <b-input :value="car.model" disabled expanded />
          </b-field>

          <b-field v-if="car.total_voyage || car.total_voyage === 0" horizontal :label="$t('totalVoyage')">
            <b-input :value="car.total_voyage" disabled expanded />
          </b-field>

          <b-field v-if="car.total_distance || car.total_distance === 0" horizontal :label="$t('totalDistance')">
            <b-input :value="car.total_distance" disabled expanded />
          </b-field>

          <b-field v-if="car.total_time_min" horizontal :label="$t('totalTime')">
            <b-input :value="car.total_time_min" disabled expanded />
          </b-field>

          <b-field horizontal :label="$t('seats')">
            <b-input v-model="car.seats" :placeholder="$t('seats')" expanded />
          </b-field>

          <b-field horizontal :label="$t('owner')">
            <b-select v-model="car.owner" :placeholder="$t('owner')" expanded>
              <option value="Kurum">Kurum</option>
              <option value="Diğer">Diğer</option>
            </b-select>
          </b-field>

          <b-field horizontal :label="$t('txtstatus')">
            <b-switch v-model="car.ready" type="is-purple" expanded />
          </b-field>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'

export default {
  title: ({ $i18n }) => $i18n.t('editCar'),
  components: {
    HeroBar,
    CardComponent,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isLoading: false,
      car: {},
    }
  },
  created () {
    this.show(this.id)
  },
  mounted () {
    this.$store.commit('setAsideActiveMenuKey', 'cars.index')
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  methods: {
    show (id) {
      this.$http.get('cars/' + id).then(({ data }) => {
        this.car = data.data
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-danger',
        })

        this.$router.push({ name: 'cars.index' })
      })
    },
    save (id) {
      this.isLoading = true

      const body = this.$_.pick(this.car, ['ready', 'seats', 'owner'])

      this.$http.put('cars/' + id, body).then(() => {
        this.$buefy.toast.open({
          message: this.$t('updated'),
          type: 'is-success',
          queue: false,
        })
      }).catch((error) => {
        const errors = this.$_.flattenDeep(this.$_.values(error.response.data.errors)).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')}:<br>${errors}`,
          type: 'is-warning',
          queue: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>